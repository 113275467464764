import Head from "next/head";
import { useEffect } from "react";

const OpenWrapScript = () => {
  useEffect(() => {
    var PWT = {};
    var gptRan = false;
    PWT.jsLoaded = function () {
      loadGpt();
    };
    window.PWT = PWT;
    (function () {
      var purl = window.location.href;
      var url = "//ads.pubmatic.com/AdServer/js/pwt/156066/582";
      var profileVersionId = "";
      if (purl.indexOf("pwtv=") > 0) {
        var regexp = /pwtv=(.*?)(&|$)/g;
        var matches = regexp.exec(purl);
        if (matches.length >= 2 && matches[1].length > 0) {
          profileVersionId = "/" + matches[1];
        }
      }
      let pwt_js = document.getElementById("pwt_js");
      if (pwt_js) {
        console.log("already pwt loaded", pwt_js);
      } else {
        var wtads = document.createElement("script");
        wtads.async = true;
        wtads.type = "text/javascript";
        wtads.id = "pwt_js";
        wtads.src = url + profileVersionId + "/pwt.js";
        var node = document.getElementsByTagName("script")[0];
        node.parentNode.insertBefore(wtads, node);
      }
    })();
    var loadGpt = function () {
      // Check the gptRan flag
      if (!gptRan) {
        gptRan = true;
        let gpt_js = document.getElementById("gpt_js");
        if (gpt_js) {
          console.log("gpt not loaded");
        } else {
          var gads = document.createElement("script");
          var useSSL = "https:" == document.location.protocol;
          gads.src =
            (useSSL ? "https:" : "http:") +
            "//securepubads.g.doubleclick.net/tag/js/gpt.js";
          gads.id = "gpt_js";
          var node = document.getElementsByTagName("script")[0];
          node.parentNode.insertBefore(gads, node);
        }
      }
    };
    // Failsafe to call gpt
    setTimeout(loadGpt, 500);
  }, []);

  return (
    <Head>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `// this function will act as a lock and will call the GPT API
          function initAdserver(forced) {
            console.log("initAdserver forced",forced);
              if((forced === true && window.initAdserverFlag !== true) || (PWT.TAM_BidsReceived && PWT.ow_BidsReceived)){
                  window.initAdserverFlag = true;
                  PWT.TAM_BidsReceived = PWT.ow_BidsReceived = false;
                  googletag.pubads().refresh(); 
              }         
          }`,
        }}
      ></script>
      {/* <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `function requestBids(){if(typeof window.PWT !== "undefined" && typeof PWT.requestBids === 'function'){PWT.requestBids(PWT.generateConfForGPT(googletag.pubads().getSlots()), function(adUnitsArray) {PWT.addKeyValuePairsToGPTSlots(adUnitsArray); PWT.ow_BidsReceived = true; window.initAdserver(true); } ); } }`,
        }}
      ></script> */}
    </Head>
  );
};

export default OpenWrapScript;
